
import { Component, Prop, Vue } from 'vue-property-decorator';
import Label from '@/models/Label';
import { UserRepository } from '@/repositories/UserRepository';
import { capitalizeWord } from '@/helpers/TranslationHelper';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import OrderStatus from '@/models/OrderStatus';
import {
    generateSearchFilterAndNavigateHome,
    getNumberOfProjectsBasedOnSearchFilters,
} from '@/helpers/IntroScreenHelper';
import { RouteNames } from '@/enums/routes/RouteNames';

@Component({
    name: 'ProjectsOverviewField',
    components: {},
})
export default class ProjectsOverviewField extends Vue {
    @Prop({ required: true }) private title!: string;
    @Prop({ required: true }) private label!: Label | null;
    @Prop({ required: true }) private state!: string;
    @Prop({ required: true }) private orderStatus!: OrderStatus | null;

    private routeNames = RouteNames;
    private numberOfProjects: null | number = null;

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private openProjectTableWithFilters() {
        this.$emit('openProjectTableWithFilters');

        generateSearchFilterAndNavigateHome({
            currentUser: this.currentUser,
            type: this.convertProjectStateNameToEnum(this.state === 'request' ? 'offer' : this.state),
            label: this.label,
            orderStatus: this.orderStatus,
            query: '',
            shouldSetActiveTab: true,
            stateSwitchForOffers: this.state === 'request',
            route: RouteNames.home,
        });
    }

    private convertProjectStateNameToEnum(projectStateName: string) {
        const capitalizedProjectStateName = capitalizeWord(projectStateName) as keyof typeof ProjectTypes;
        return ProjectTypes[capitalizedProjectStateName] as keyof typeof ProjectTypes;
    }

    private async mounted() {
        const isUserSalesman = this.currentUser ? this.currentUser.isSalesman : false;

        this.numberOfProjects = await getNumberOfProjectsBasedOnSearchFilters({
            type: this.state,
            label: this.label,
            orderStatus: this.orderStatus,
            currentUser: isUserSalesman ? this.currentUser : null,
        });
    }
}
