
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
// @ts-ignore
import VueApexCharts from 'vue-apexcharts';
import ChartHeader from '@/views/StatisticsContent/ChartHeader.vue';
import { DashboardReportFields } from '@/interfaces/components/Statistics/DashboardReport';

@Component({
    name: 'Chart',
    components: {
        chart: VueApexCharts,
        ChartHeader,
    },
})
export default class Chart extends Vue {
    @Prop({ required: true }) private data!: DashboardReportFields;
    @Prop({ required: true }) private isEdit!: boolean;

    private chartWidth = '100%';
    private series: null | Array<{ name: string; data: number[] }> = null;
    private options: any = null;

    private get chartHeight() {
        switch (this.data.size) {
            case 'L':
                return '200%';
            case 'M':
                return '150%';
            default:
                return '130%';
        }
    }

    private get axisTextFontSize() {
        switch (this.data.size) {
            case 'L':
                return '14px';
            case 'M':
                return '13px';
            default:
                return '10px';
        }
    }

    private get chartSize() {
        switch (this.data.size) {
            case 'S':
                return {
                    width: '48%',
                    height: '48%',
                };
            default:
                return {
                    width: '100%',
                    height: '100%',
                };
        }
    }

    private changeChartSize(size: 'S' | 'M' | 'L') {
        this.data.size = size;
    }

    private updateChart(data: any) {
        this.options = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
                events: {
                    mounted: (chart: any) => {
                        chart.windowResizeHandler();
                    },
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: data.statisticReport.data.fields[1].name === 'Y axis' ? false : true,
                },
            },
            xaxis: {
                categories: data.statisticReport.data.values.map((item: any) => item[0]),
                labels: {
                    rotate: -35,
                    trim: false,
                    hideOverlappingLabels: false,
                    style: {
                        colors: [],
                        fontSize: this.axisTextFontSize,
                        fontWeight: 400,
                        cssClass: 'chart-label',
                        maxWidth: '1rem',
                    },
                },
            },
            dataLabels: {
                enabled: false,
                formatter: (val: number) => {
                    return Number(val).toLocaleString();
                },
            },
            yaxis: {
                // max: 50,
                // tickAmount: 5,
                logarithmic: true,
                forceNiceScale: true,
                labels: {
                    formatter: (val: number) => {
                        if (typeof val === 'number') {
                            return Number(val).toLocaleString();
                        } else {
                            return val;
                        }
                    },
                    style: {
                        fontSize: this.axisTextFontSize,
                    },
                },
            },
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: '#b6b6b6',
                    width: 1,
                    dashArray: 0,
                },
            },
        };

        this.series = [
            {
                name: data.statisticReport.data.fields[2].name,
                data: data.statisticReport.data.values.map((item: any) => item[1]),
            },
        ];
    }

    @Watch('data', { deep: true, immediate: true })
    private update(newChartData: any) {
        if (newChartData.statisticReport.data.fields.length) {
            this.updateChart(newChartData);
        }
    }
}
