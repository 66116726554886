
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ChartHeader from '@/views/StatisticsContent/ChartHeader.vue';
import ProjectsOverviewField from '@/views/StatisticsContent/ProjectsOverviewField.vue';
import IntroScreenRepository from '@/repositories/IntroScreenRepository';

@Component({
    name: 'ProjectsOverview',
    components: {
        ChartHeader,
        ProjectsOverviewField,
    },
})
export default class ProjectsOverview extends Vue {
    @Prop({ required: true }) private isEdit!: boolean;

    private get fields() {
        const introScreen = IntroScreenRepository.getFirst();
        if (introScreen == null) {
            return [];
        }

        return introScreen.introScreenItems.sort((a, b) => {
            return a.rowNumber - b.rowNumber;
        });
    }
}
