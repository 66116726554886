
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';

@Component({
    name: 'Comments',
    components: {},
})
export default class Comments extends Vue {
    @Prop({ required: true }) private data!: any;
    private projectTabValues = ProjectTabValues;
    private routeNames = RouteNames;
}
