
import { Component, Vue, Prop } from 'vue-property-decorator';
import Chart from '@/views/StatisticsContent/Chart.vue';
import PieChart from '@/views/StatisticsContent/PieChart.vue';
import TotalReport from '@/views/StatisticsContent/TotalReport.vue';
import TableReport from '@/views/StatisticsContent/TableReport.vue';
import ProjectsOverview from '@/views/StatisticsContent/ProjectsOverview.vue';
import Matrix from '@/views/StatisticsContent/Matrix.vue';
import News from '@/views/News.vue';
import { ReportTypes } from '@/interfaces/components/Statistics/ReportTypes';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
// @ts-ignore
import downloadFile from 'downloadjs';
import { DashboardReportFields } from '@/interfaces/components/Statistics/DashboardReport';

@Component({
    name: 'StatisticsContent',
    components: {
        TotalReport,
        TableReport,
        ProjectsOverview,
        Chart,
        PieChart,
        Matrix,
        News,
    },
})
export default class StatisticsContent extends Vue {
    @Prop({ required: false }) private reports!: DashboardReportFields[];
    @Prop({ required: true }) private isEdit!: boolean;

    private reportTypes = ReportTypes;
    private tablePagination = { paginationSize: 5, activePrintingReport: '' };
    private isPrinting = false;

    private startDrag(event: any) {
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.effectAllowed = 'move';

        event.target.id = 'dragged';
    }

    private onDrop(event: any) {
        if (!this.isEdit) {
            return;
        }

        let dropTarget;
        const droppedItem = document.getElementById('dragged');

        if (event.target.id === 'content-container') {
            // Get width of drop target
            const containerWidth = event.target.offsetWidth;

            // Locate closest content item if clicked outside of it
            dropTarget = this.findClosestContentItem(event, containerWidth);
        } else {
            dropTarget = event.target.closest('.content-item');
        }

        // Move dragged item
        if (dropTarget && droppedItem) {
            droppedItem.removeAttribute('id');

            // Get index of dropped item and drop target
            const dropTargetIndex = dropTarget.getAttribute('data-index');
            const droppedItemIndex = droppedItem.getAttribute('data-index');

            if (dropTargetIndex === droppedItemIndex) {
                return;
            }

            const toIndex = this.reports.findIndex((report) => report.id === dropTargetIndex);
            const fromIndex = this.reports.findIndex((report) => report.id === droppedItemIndex);

            this.changeItemPosition(toIndex, fromIndex);
        }
    }

    private changeItemPosition(to: number, from: number) {
        const item = this.reports[from];

        this.reports.splice(from, 1);
        this.reports.splice(to, 0, item);
    }

    private findClosestContentItem(event: any, width: number) {
        let dropTarget;

        for (let i = 4; i > 1; i--) {
            const x = event.clientX - width / i - 50;
            const y = event.clientY;

            dropTarget = document.elementFromPoint(x, y)?.closest('.content-item');

            if (dropTarget) {
                return dropTarget;
            }
        }
    }

    private async downloadReport(data: {
        reportId: string;
        name: string;
        reportData: any;
        fileType: string;
        reportType: string;
    }) {
        this.expandTableForPrinting(data.reportId);
        await this.$nextTick();

        switch (data.fileType) {
            case 'pdf':
                const reportName = data?.reportData?.title ?? data.reportId;
                // @ts-ignore
                await this.$htmlToPaper(data.reportId === 'news' ? 'news-table' : data.reportId, {
                    windowTitle: this.$t('Roltek dashboard') + ' - ' + reportName,
                });
                break;
            case 'png':
                this.downloadChartIMG(data);
                break;
            case 'csv':
                this.downloadCSV(data);
                break;
        }

        await this.$nextTick();
        this.revertTableToNormalSize();
    }

    private expandTableForPrinting(reportId: string) {
        this.tablePagination.activePrintingReport = reportId;
        this.setPrintingStateToActive();
    }

    private revertTableToNormalSize() {
        this.tablePagination.activePrintingReport = '';
        this.setPrintingStateToFalse();
    }

    private setPrintingStateToActive() {
        this.isPrinting = true;
    }

    private setPrintingStateToFalse() {
        this.isPrinting = false;
    }

    private async prepareTablesForPrinting() {
        this.setPrintingStateToActive();
        await this.$nextTick();

        // @ts-ignore
        await this.$htmlToPaper('dashboard-wrapper', {
            windowTitle: this.$t('Roltek dashboard'),
        });

        await this.$nextTick();
        this.setPrintingStateToFalse();
    }

    private downloadChartIMG(data: { reportId: string; name: string; reportData: any; fileType: string }) {
        if (data.reportData.type === this.reportTypes.chart || data.reportData.type === this.reportTypes.pieChart) {
            // Get raw img data from chart and download it
            const chartRef = (this.$refs[data.reportId] as Vue[])[0].$refs.chartRef;
            // @ts-ignore
            chartRef.dataURI().then(({ imgURI }) => {
                downloadFile(imgURI as string, data.name + '.png', 'image/png');
            });
        }
    }

    private downloadCSV(data: {
        reportId: string;
        name: string;
        reportData: any;
        fileType: string;
        reportType: string;
    }) {
        let csvData = '';
        let titles = '';
        const reportRef = this.$refs[data.reportId] as Vue;

        switch (data.reportType) {
            case this.reportTypes.projectsOverview:
                {
                    const names = reportRef.$el.querySelectorAll('.projects-overview__status');
                    const values = reportRef.$el.querySelectorAll('.projects-overview__value');

                    // Concatonate names and values to csv string and download it
                    for (let i = 0; i < names.length; i++) {
                        csvData += `${names[i].textContent}, ${values[i].textContent}\n`;
                    }

                    const csvFile = new Blob([csvData], { type: 'text/csv' });
                    downloadFile(csvFile, data.name + '.csv', 'text/csv');
                }
                break;

            // CHARTS / PIE CHARTS
            case this.reportTypes.chart:
            case this.reportTypes.pieChart:
                {
                    // Concatonate chart data to csv string and download it
                    csvData += `${data.reportData.fields[0].name}, ${
                        data.reportData.fields[data.reportData.type === this.reportTypes.chart ? 2 : 1].name
                    }\n`;

                    for (let i = 0; i < data.reportData.values.length; i++) {
                        csvData += `${data.reportData.values[i][0]}, ${data.reportData.values[i][1]}\n`;
                    }

                    const csvFile = new Blob([csvData], { type: 'text/csv' });
                    downloadFile(csvFile, data.name + '.csv', 'text/csv');
                }
                break;

            // TABLES / MATRIX
            case this.reportTypes.matrix:
            case this.reportTypes.tableReport:
            case this.reportTypes.news:
                {
                    // CONCATONATE TABLE DATA TO CSV STRING AND DOWNLOAD IT
                    const tableElement = Array.isArray(reportRef)
                        ? reportRef[0].$refs.tableRef
                        : reportRef.$refs.tableRef;
                    const rows = tableElement.$el.querySelectorAll('tbody tr');
                    for (let i = 0; i < rows.length; i++) {
                        const cells = rows[i].querySelectorAll('td');
                        for (let j = 0; j < cells.length; j++) {
                            csvData += `${cells[j].innerText},`;
                        }
                        csvData += '\n';
                    }

                    // CONCATONATE TABLE HEADERS TO CSV STRING AND ADD IT TO THE BEGINNING OF THE FILE
                    for (let i = 0; i < tableElement.columns.length; i++) {
                        titles += `${tableElement.columns[i].title},`;
                    }
                    csvData = titles + '\n' + csvData;

                    const csvFile = new Blob([csvData], { type: 'text/csv' });
                    downloadFile(csvFile, data.name + '.csv', 'text/csv');
                }
                break;
        }
    }

    private created() {
        EventBus.$on(EventBusEvents.downloadReport, this.downloadReport);
        EventBus.$on(EventBusEvents.prepareTablesForPrinting, this.prepareTablesForPrinting);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.downloadReport, this.downloadReport);
        EventBus.$off(EventBusEvents.prepareTablesForPrinting, this.prepareTablesForPrinting);
    }

    private mounted() {
        this.reports = this.reports.sort((a, b) => a.position - b.position);
    }
}
