
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import StatisticsContent from '@/views/StatisticsContent.vue';
import StatisticsSidebar from '@/views/StatisticsSidebar.vue';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { fetchChatProjects } from '@/helpers/chat/ChatHelper';
import { UserRepository } from '@/repositories/UserRepository';
import DashboardRepository from '@/repositories/DashboardRepository';
import DashboardReportRepository from '@/repositories/DashboardReportRepository';
import ProjectRepository from '@/repositories/ProjectRepository';
import { fetchActivitiesProjects } from '@/helpers/TableHelper';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import Dashboard from '@/models/Dashboard';
import DashboardReport from '@/models/DashboardReport';
import Report from '@/models/Report';
import { DashboardReportFields } from '@/interfaces/components/Statistics/DashboardReport';
import { DashboardReportData } from '@/interfaces/components/Statistics/DashboardReportData';
import { ChatMessage } from '@/interfaces/components/chat/ChatMessage';
import moment from 'moment';
import IntroScreen from '@/models/IntroScreen';
import CMSUser from '@/models/CMSUser';
import News from '@/models/News';
import { ReportTimeFrame } from '@/interfaces/components/Statistics/ReportTimeFrame';
import { AxiosResponse } from 'axios';
import CMSUserRepository from '@/repositories/CMSUserRepository';

@Component({
    name: 'StatisticsDashboard',
    components: {
        StatisticsContent,
        StatisticsSidebar,
    },
})
export default class StatisticsDashboard extends Vue {
    @Getter('tableData/nextSteps')
    private nextSteps!: any[];
    private isExistingDashboard: boolean = false;
    private isEditModeOn: boolean = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private isLoading: boolean = false;
    private currentUserId: string = '1';
    private filteredUserName: string = '';
    private dashboardId: string = '';
    private dashboardReports: DashboardReportFields[] = [];
    private timeFrame: ReportTimeFrame = {
        year: new Date().getFullYear(),
        week: 'Current',
        month: '/',
    };
    private expandModeForPrinting: boolean = false;

    // Translated object
    private get timeFrameNext() {
        return { ...this.timeFrame, week: this.$t(this.timeFrame.week), month: this.$t(this.timeFrame.month) };
    }

    private sidebarData: { newTasks: any[]; newComments: ChatMessage[] } = {
        newTasks: [],
        newComments: [],
    };

    // Chat messages logic
    private searchOptions = {
        page: 1,
        size: 10,
        query: '',
        total: 0,
    };
    private activeTabKey = 1;
    private newsReports: any[] = [];

    private get chatProjects() {
        return ProjectRepository.getChatProjects();
    }

    private get position() {
        return this.dashboardReports.length;
    }

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private editDashboard() {
        this.isEditModeOn = !this.isEditModeOn;
    }

    private async printDashboard() {
        EventBus.$emit(EventBusEvents.prepareTablesForPrinting);
    }

    private formatTime(timestamp: string = '') {
        const formattedDate = new Date(timestamp);
        const min = formattedDate.getMinutes();
        const date = formattedDate.toLocaleDateString();
        const time = formattedDate.getHours() + ':' + (min < 10 ? '0' + min : min);

        return { date, time };
    }

    private async serializeTaskbarData() {
        // Serialize latest activities
        this.serializeNewActivities();

        // Serialize latest chat messages
        this.serializeNewComments();
    }

    private serializeNewActivities() {
        for (const task of this.nextSteps) {
            this.sidebarData.newTasks.push({
                date: moment(task.dateFrom).format('DD.MM.YYYY'),
                time: moment(task.dateFrom).format('HH:mm'),
                name: task.lead?.project?.client?.name,
                status: task?.type?.name,
                projectId: task.lead?.project?.id,
            });
        }

        return Promise.resolve();
    }

    private serializeNewComments() {
        for (let i = 0; i < 3; i++) {
            const project = this.chatProjects[i];

            this.sidebarData.newComments.push({
                title: project.projectInfo.clientName,
                user: project.projectInfo.createdBy,
                date: moment(project.projectInfo.createdAt).format('DD.MM.YYYY'),
                time: moment(project.projectInfo.createdAt).format('HH:mm'),
                text: project.projectInfo.latestMessage,
                projectId: project.projectInfo.projectId,
            });
        }
    }

    // Taskbard data fetching
    private fetchChatProjects() {
        if (this.currentUserId == null) {
            return Promise.reject();
        }

        try {
            fetchChatProjects({
                activeTabKey: this.activeTabKey,
                userId: this.currentUserId,
                searchOptions: this.searchOptions,
            });
        } catch (error) {
            this.isLoading = false;
            return Promise.reject(error);
        }

        return Promise.resolve();
    }

    private async fetchDashboardData() {
        try {
            if (this.dashboardId) {
                const dashboard = await Dashboard.getById(this.dashboardId);
                this.dashboardReports = dashboard.data.dashboardReports.slice();

                this.isExistingDashboard = true;
            } else {
                const response = await Dashboard.getCurrent();

                if (response?.data) {
                    this.dashboardReports = response.data.dashboardReports.slice();
                    this.dashboardId = String(response.data.id);
                }
            }

            // Fetch report data individually after fetching all reports
            await this.fetchIndividualReportData();
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error fetching dashboard!'),
                description: this.$t(e as string),
            });
            this.isLoading = false;
            return;
        }

        return Promise.resolve();
    }

    private async fetchIndividualReportData() {
        if (this.dashboardReports.length) {
            // Get report data for each report using promise all in parallel
            const promises: Array<Promise<{ status: string; value: DashboardReportData }>> = [];
            let fetchedReports: Array<
                PromiseSettledResult<{
                    status: string;
                    value: DashboardReportData;
                }>
            > = [];

            this.dashboardReports.map((report: any) => {
                promises.push(
                    DashboardReport.getReport(report.id, this.timeFrameNext, this.currentUserId).then((res) => ({
                        ...res.data,
                        id: report.id,
                        shouldExpandTable: false,
                    }))
                );
            });

            try {
                fetchedReports = await Promise.allSettled(promises);
            } catch (e) {
                this.$notification.error({
                    message: this.$t('Error fetching reportData!'),
                    description: this.$t(e as string),
                });
                this.isLoading = false;
                return;
            }

            if (fetchedReports.length) {
                // Replace report data field with fetched data
                fetchedReports.map((report: any) => {
                    if (report.status === 'fulfilled') {
                        const index = this.dashboardReports.findIndex(
                            (fetchedReportData: any) => fetchedReportData.id === report.value.id
                        );
                        this.dashboardReports[index].statisticReport.data = { ...report.value };
                    }
                });
            }
        }

        return Promise.resolve();
    }

    // Fetching a new report from BE statistics report and adding it to dashboard reports
    private async addNewReport(report: DashboardReportFields, size: 'S' | 'M' | 'L') {
        let fetchedReport;
        this.isLoading = true;

        const isReportInDashboard = this.checkIfReportIsInDashboard(report.id);

        if (isReportInDashboard) {
            this.$notification.error({
                message: this.$t('Report already in dashboard!'),
                description: '',
            });
            this.isLoading = false;
            return;
        }

        try {
            fetchedReport = await DashboardReport.createNewReport({
                report,
                size,
                position: this.position,
                dashboardId: this.dashboardId,
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error creating report!'),
                description: '',
            });

            this.isLoading = false;
            return;
        }

        this.addNewReportAndFetchDataForIt(fetchedReport);
    }

    private checkIfReportIsInDashboard(reportId: string) {
        // Check if report id is already in dashboard reports: statisticReport and throw error if it is
        const isReportInDashboard = this.dashboardReports.some((dashboardReport: DashboardReportFields) => {
            return dashboardReport.statisticReport.id === reportId;
        });

        return isReportInDashboard;
    }

    private async addNewReportAndFetchDataForIt(fetchedReport: AxiosResponse<DashboardReportFields>) {
        let reportData: AxiosResponse<DashboardReportData>;
        this.dashboardReports.push(fetchedReport.data);

        try {
            reportData = await DashboardReport.getReport(fetchedReport.data.id, this.timeFrameNext, this.currentUserId);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error fetching report data!'),
                description: '',
            });
            return;
        } finally {
            this.isLoading = false;
        }

        // Add report data to report in dashboard reports dinamically
        const index = this.dashboardReports.findIndex((report: any) => report.id === fetchedReport.data.id);
        this.$set(this.dashboardReports[index].statisticReport, 'data', { ...reportData.data });
    }

    private deleteChart(id: string) {
        this.isLoading = true;

        // Delete chart locally
        const index = this.dashboardReports.findIndex((report: any) => report.id === id);
        this.dashboardReports.splice(index, 1);

        // Delete chart from database
        try {
            DashboardReport.deleteExistingReport(id);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error deleting report!'),
                description: this.$t(e as string),
            });
            return;
        } finally {
            this.isLoading = false;
        }

        this.$notification.success({
            message: this.$t('Report deleted successfully!'),
            description: '',
        });
    }

    private async saveDashboard() {
        this.isLoading = true;

        try {
            // Set correct positions for all reports
            this.dashboardReports.forEach((report: any, index: number) => {
                report.position = index;

                DashboardReport.updateExistingReport(report, this.dashboardId);
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error saving dashboard!'),
                description: this.$t(e as string),
            });
            this.isLoading = false;
            return;
        }

        this.$notification.success({
            message: this.$t('Dashboard successfully saved'),
            description: '',
        });

        this.isEditModeOn = false;
        this.isLoading = false;
    }

    private notifyUserOnEditModeToggle() {
        // Remove any existing notifications
        this.$notification.destroy();

        if (this.isEditModeOn) {
            this.$notification.info({
                message: this.$t('You are now in edit mode'),
                description: this.$t('You can now add, remove, change position or resize reports'),
                duration: 3,
            });
        } else {
            this.$notification.info({
                message: this.$t('You are now in view mode'),
                description: this.$t('Your changes have not been saved'),
                duration: 3,
            });
        }
    }

    private setDashboardPrintOption(data: { reportId: string; shouldExpandTableForPrint: boolean }) {
        const index = this.dashboardReports.findIndex((report: DashboardReportFields) => report.id === data.reportId);

        this.$set(this.dashboardReports[index], 'shouldExpandTable', data.shouldExpandTableForPrint);

        if (!data.shouldExpandTableForPrint) {
            this.expandModeForPrinting = false;
        }
    }

    private setReportsExpandState() {
        this.dashboardReports.forEach((report: DashboardReportFields) => {
            this.$set(report, 'shouldExpandTable', this.expandModeForPrinting);
        });
    }

    private async filterDashboardReportsByDateAndUser(userId: string) {
        this.isLoading = true;
        this.currentUserId = userId;
        this.filteredUserName = CMSUserRepository.getNameById(userId);

        this.timeFrameNext.year = String(this.timeFrameNext.year);
        this.timeFrameNext.week = String(this.timeFrameNext.week);
        this.timeFrameNext.month = String(this.timeFrameNext.month);
        try {
            const response = await Dashboard.getByUserId(userId, this.dashboardId);

            if (response?.data) {
                this.dashboardReports = response.data.dashboardReports.slice();
                this.dashboardId = String(response.data.id);
            }

            await this.fetchIndividualReportData();
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error filtering reports!'),
                description: this.$t(e as string),
            });
            return;
        } finally {
            this.isLoading = false;
        }

        this.$notification.success({
            message: this.$t('Dashboard reports filtered successfully!'),
            description: '',
        });
    }

    private async mounted() {
        this.isLoading = true;
        // DashboardReport.deleteExistingReport('144');

        // Get dashboard id from route params
        const params = this.$route.params;
        if (params.dashboardId) {
            this.dashboardId = String(params.dashboardId);
        }

        if (this.currentUser) {
            this.currentUserId = this.currentUser.id;
            this.filteredUserName = this.currentUser.name;
        }

        // Fetch everything in parallel
        try {
            await Promise.allSettled([
                Report.getAll(),
                // Fetch activities and chat projects for taskbar
                // fetchActivitiesProjects(this.currentUser!.id),
                // Get projects overview data
                IntroScreen.getAll(),
                News.getAll(),
                CMSUser.getAll(),
                // Fetch taskbar and dashboard data for a current dashboard
                // this.fetchChatProjects(),
                this.fetchDashboardData(),
            ]);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error fetching dashboard!'),
                description: this.$t(e as string),
            });
            return;
        } finally {
            this.isLoading = false;
        }

        // this.serializeTaskbarData();
    }

    private created() {
        EventBus.$on(EventBusEvents.deleteChart, this.deleteChart);
        EventBus.$on(EventBusEvents.setDashboardPrintOption, this.setDashboardPrintOption);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.deleteChart, this.deleteChart);
        EventBus.$off(EventBusEvents.setDashboardPrintOption, this.setDashboardPrintOption);
    }
}
