
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EventBus } from '@/helpers/EventBusHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import { ReportTypes } from '@/interfaces/components/Statistics/ReportTypes';
import { DashboardReportFields } from '@/interfaces/components/Statistics/DashboardReport';

@Component({
    name: 'ChartHeader',
})
export default class ChartHeader extends Vue {
    @Prop({ required: true }) private data!: DashboardReportFields;
    @Prop({ required: true }) private isEdit!: boolean;
    @Prop({ required: false, default: true }) private isResizable!: boolean;
    @Prop({ required: false, default: true }) private shouldShowDownloadBar!: boolean;
    @Prop({ required: false, default: false }) private shouldHideDownloadPNGOption!: boolean;
    @Prop({ required: false, default: false }) private shouldNavigate!: boolean;
    @Prop({ required: false, default: false }) private showPrintExpandOption!: boolean;

    private thisReference = this;
    private routeNames = RouteNames;
    private isEditModalVisible = false;
    private isDownloadModalVisible = false;
    private chartSize = this.data?.size || 'M';
    private chartType = this.data?.statisticReport?.data.type;

    private get downloadOptions() {
        const options = [
            {
                title: 'Download PDF',
                icon: 'file-pdf',
                fileType: 'pdf',
            },
            {
                title: 'Download PNG',
                icon: 'file-image',
                fileType: 'png',
            },
            {
                title: 'Download CSV',
                icon: 'file-text',
                fileType: 'csv',
            },
        ];

        if (this.shouldHideDownloadPNGOption) {
            options.splice(1, 1);
        }

        return options;
    }

    private get showPrintToggle() {
        return !this.isEdit && this.showPrintExpandOption;
    }

    private get isSmallChart() {
        return (
            this.chartSize === 'S' && (this.chartType === ReportTypes.pieChart || this.chartType === ReportTypes.chart)
        );
    }

    private updateTogglePrintOption(shouldExpandTableForPrint: boolean) {
        EventBus.$emit(EventBusEvents.setDashboardPrintOption, { reportId: this.data.id, shouldExpandTableForPrint });
    }

    private openModal() {
        this.isEditModalVisible = true;
    }

    private openDownloadModal() {
        this.isDownloadModalVisible = true;
    }

    private showDeleteConfirm(thisReference: any) {
        this.$confirm({
            title: 'Are you sure delete this report?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                thisReference.deleteChart();
            },
        });
    }

    private downloadReport(fileType: string) {
        EventBus.$emit(EventBusEvents.downloadReport, {
            reportId: this.data.id,
            name: this.data?.statisticReport?.name,
            reportData: this.data?.statisticReport?.data,
            fileType,
            reportType: this.data?.statisticReport?.data.type,
        });

        this.isDownloadModalVisible = false;
    }

    private deleteChart() {
        EventBus.$emit(EventBusEvents.deleteChart, this.data.id);
    }

    private changeSize() {
        this.isEditModalVisible = false;
        this.$emit('changeChartSize', this.chartSize);
    }
}
