
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ChartHeader from '@/views/StatisticsContent/ChartHeader.vue';
import NewsAddNew from '@/views/NewsAddNew.vue';
import News from '@/models/News';
import NewsRepository from '@/repositories/NewsRepository';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import moment from 'moment';
import { NewsReport } from '@/interfaces/components/news/NewsReport';
import { DashboardNewsColumn } from '@/interfaces/components/news/DashboardNewsColumn';

@Component({
    name: 'NewsReports',
    components: {
        ChartHeader,
        NewsAddNew,
    },
})
export default class NewsReports extends Vue {
    @Prop({ required: true }) private isEdit!: boolean;
    private activeNewsId: string | null = null;
    private isArchivedFilterOn: boolean = false;
    private searchQuery: string = '';
    private selectedRowKeys: string[] = [];
    private isNewsModalVisible: boolean = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private cellPaddingClass: string = 'cell-padding--large';

    private columns = [
        { title: 'Title', dataIndex: 'title', key: 'title', class: this.cellPaddingClass },
        { title: 'Date created', dataIndex: 'createdAt', key: 'createdAt', class: this.cellPaddingClass },
    ];

    private get activeNews(): DashboardNewsColumn[] {
        const reports = NewsRepository.getAll() as NewsReport[];
        if (!reports) {
            return [];
        }

        return reports
            .map((report: NewsReport) => {
                return {
                    key: report.id,
                    title: report.title,
                    createdAt: moment(report.createdAt).format('DD.MM.YYYY'),
                    locale: report?.locale?.code ?? this.currentLocale,
                };
            })
            .filter((report) => report.locale === this.currentLocale);
    }

    private get currentLocale() {
        if (this.$i18n == null) {
            return 'sl';
        }
        return this.$i18n.locale;
    }

    private openNewsReport(reportId: string) {
        this.activeNewsId = reportId;
        this.openPopup();
    }

    private async fetchAllNewsReports() {
        this.loadingOverlay.start();

        try {
            await News.getAll();
        } catch (e) {
            Promise.reject(e);
        } finally {
            this.loadingOverlay.stop();
        }

        return Promise.resolve();
    }

    private async fetchArchivedNewsReports() {
        this.loadingOverlay.start();

        try {
            await News.getAllArchived();
        } catch (e) {
            Promise.reject(e);
        } finally {
            this.loadingOverlay.stop();
        }

        return Promise.resolve();
    }

    private openPopup() {
        this.isNewsModalVisible = true;
    }

    private closePopup() {
        this.isNewsModalVisible = false;
    }

    @Watch('isArchivedFilterOn', { immediate: false })
    private switchActiveReports() {
        if (this.isArchivedFilterOn) {
            this.fetchArchivedNewsReports();
        } else {
            this.fetchAllNewsReports();
        }
    }
}
