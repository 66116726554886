
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ChartHeader from '@/views/StatisticsContent/ChartHeader.vue';
import VueApexCharts from 'vue-apexcharts';
import { DashboardReportFields } from '@/interfaces/components/Statistics/DashboardReport';

@Component({
    name: 'PieChart',
    components: {
        chart: VueApexCharts,
        ChartHeader,
    },
})
export default class PieChart extends Vue {
    @Prop({ required: true }) private data!: DashboardReportFields;
    @Prop({ required: true }) private isEdit!: boolean;

    private chartWidth = '100%';
    private series: null | number[] = null;
    private options: any = null;

    private get chartHeight() {
        switch (this.data.size) {
            case 'L':
                return '150%';
            case 'M':
                return '120%';
            default:
                return '80%';
        }
    }

    private get chartFontSize() {
        switch (this.data.size) {
            case 'L':
                return '15px';
            case 'M':
                return '13px';
            default:
                return '10px';
        }
    }

    private get chartSize() {
        switch (this.data.size) {
            case 'S':
                return {
                    width: '48%',
                    height: '48%',
                };
            default:
                return {
                    width: '100%',
                    height: '100%',
                };
        }
    }

    private changeChartSize(size: 'S' | 'M' | 'L') {
        this.data.size = size;
    }

    private updateChart(data: any) {
        this.options = {
            chart: {
                type: 'pie',
                toolbar: {
                    show: false,
                },
                events: {
                    mounted: (chart: any) => {
                        chart.windowResizeHandler();
                    },
                },
            },
            labels: data.statisticReport.data.values.map((item: any) => item[0]),
            dataLabels: {
                enabled: this.data.size === 'S' ? false : true,
                formatter: (val: any, opts: any) => {
                    return opts.w.globals.series[opts.seriesIndex];
                },
                style: {
                    fontSize: this.chartFontSize,
                },
            },
            tooltip: {
                y: {
                    // Format percentage for the tooltip;
                    formatter: (value: any, opts: any) => {
                        const sum = opts.globals.series.reduce((a: number, b: number) => a + b, 0);
                        const current = opts.globals.series[opts.seriesIndex];
                        const percentage = (current / sum) * 100;
                        return value + ` (${percentage.toFixed(0)}%)`;
                    },
                },
            },
            legend: {
                // Format legend data
                formatter: (seriesName: any, opts: any) => {
                    return [
                        `<span class="legend-title">${seriesName}<span/>`,
                        `<span class="legend-amount">${opts.w.globals.series[opts.seriesIndex]}<span/>`,
                        ,
                    ];
                },
                itemMargin: {
                    vertical: 5,
                },

                position: 'right',
                fontSize: this.chartFontSize,
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 150,
                        },
                        legend: {
                            position: 'center',
                        },
                    },
                },
            ],
        };

        this.series = data.statisticReport.data.values.map((item: any) => item[1]);
    }

    @Watch('data', { deep: true, immediate: true })
    private update(newVal: any) {
        if (newVal.statisticReport.data.values.length) {
            this.updateChart(newVal);
        }
    }
}
