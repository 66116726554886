var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"projects-overview"},[_c('div',{class:['border-wrapper', { 'edit-border': _vm.isEdit }]},[(_vm.isEdit)?_c('a-icon',{staticClass:"drag-icon",attrs:{"type":"drag"}}):_vm._e(),_c('chart-header',{attrs:{"data":{
                title: _vm.$t('Projects overview'),
                subtitle: _vm.$t('View all'),
                navigateTo: { name: '/', query: 'Vsi projekti' },
                reportData: _vm.fields,
                id: 'projects-overview',
                type: 'projects-overview',
            },"isEdit":_vm.isEdit,"isResizable":false,"shouldNavigate":true,"shouldHideDownloadPNGOption":true}}),_c('div',{ref:"projects-overview-fields",staticClass:"projects-overview__data-wrapper"},_vm._l((_vm.fields),function(field){return _c('ProjectsOverviewField',{key:field.id,attrs:{"label":field.projectLabel,"order-status":field.orderStatus,"state":field.state,"subtitle":field.filteredProjects,"title":field.name}})}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }