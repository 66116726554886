var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"comments-wrapper"},[_c('div',{staticClass:"comments__header"},[_c('span',{staticClass:"comments__title"},[_vm._v(_vm._s(_vm.$t('New comments')))]),_c('router-link',{attrs:{"to":{ name: _vm.routeNames.chat },"target":"_blank"}},[_c('span',{staticClass:"comments__view-button"},[_vm._v(_vm._s(_vm.$t('View all')))])])],1),_c('div',{staticClass:"comments__content"},_vm._l((_vm.data),function(field){return _c('div',{staticClass:"comments__field"},[_c('div',{staticClass:"field__header"},[_c('span',{staticClass:"field__title"},[_vm._v(_vm._s(field.title))]),_c('div',{staticClass:"field__info-wrapper"},[_c('div',{staticClass:"field__info"},[_c('span',{staticClass:"field__info-title"},[_vm._v(" "+_vm._s(_vm.$t('Project'))+": ")]),_c('span',{staticClass:"field__id"},[_vm._v(_vm._s(field.projectId))])]),_c('router-link',{attrs:{"to":{
                            name: _vm.routeNames.project,
                            params: {
                                id: field.projectId,
                            },
                            query: {
                                initialTab: _vm.projectTabValues.Basic,
                            },
                        },"target":"_blank"}},[_c('a-icon',{staticClass:"field__icon",attrs:{"type":"eye"}})],1)],1)]),_c('div',{staticClass:"field__content"},[_c('div',{staticClass:"field__content__text"},[_vm._v(_vm._s(field.text))]),_c('div',{staticClass:"field__content__info"},[_c('span',{staticClass:"field__content__user"},[_vm._v(_vm._s(field.user)+" • ")]),_c('span',{staticClass:"field__content__date"},[_vm._v(_vm._s(field.date)+" ")]),_c('span',{staticClass:"field__content__time"},[_vm._v(_vm._s(field.time))])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }