
import { Component, Vue, Prop } from 'vue-property-decorator';
import ChartHeader from '@/views/StatisticsContent/ChartHeader.vue';
import Report from '@/models/Report';
import { DashboardReportFields } from '@/interfaces/components/Statistics/DashboardReport';
import { SortFunctions } from '@/interfaces/components/Statistics/SortFunctions';
import { TableSorter } from '@/interfaces/components/Statistics/TableSorter';
import { TableColumn } from '@/interfaces/components/Statistics/StatisticsTableColumn';

@Component({
    name: 'Matrix',
    components: {
        ChartHeader,
    },
})
export default class Matrix extends Vue {
    @Prop({ required: true }) private data!: DashboardReportFields;
    @Prop({ required: true }) private isEdit!: boolean;
    @Prop({ required: false, default: false }) private isPrinting!: boolean;
    @Prop({ required: true }) private tablePagination!: {
        paginationSize: number;
        activePrintingReport: string;
    };

    private sortedTableData: null | object = null;

    private cellPaddingClass: string = 'cell-padding--medium';

    private get reportSize() {
        switch (this.data.size) {
            case 'S':
                return {
                    width: '48%',
                };
            default:
                return {
                    width: '100%',
                };
        }
    }

    private get pageSize() {
        if (this.isPrinting && this.data.shouldExpandTable) {
            return false;
        }

        if (this.isPrinting && this.tablePagination.activePrintingReport === this.data.id) {
            return false;
        }

        return { pageSize: this.tablePagination.paginationSize };
    }

    private get responsiveFontSize() {
        switch (this.data.size) {
            case 'S':
                this.cellPaddingClass = 'cell-padding--small';
                return {
                    'font-size': '0.6rem',
                    color: '#000',
                };
            case 'M':
                this.cellPaddingClass = 'cell-padding--medium';
                return {
                    'font-size': '0.75rem',
                };
            case 'L':
                this.cellPaddingClass = 'cell-padding--large';
                return {
                    'font-size': '0.9rem',
                };
        }
    }

    private get columns() {
        if (this.data.statisticReport.data.fields) {
            return this.data.statisticReport.data.fields.map((column: Record<string, any>) => {
                return {
                    class: this.cellPaddingClass,
                    title: this.$t(column.name),
                    dataIndex: column.name.toLowerCase(),
                    key: column.name.toLowerCase(),
                    defaultSortOrder: 'descend',
                    sorter: true,
                    type: column.type,
                };
            });
        }

        return [];
    }

    private get tableData() {
        // Get data for table
        const data = this.data.statisticReport.data.values?.map((row: Array<any>, rowIndex: number) => {
            const newRow: any = {};

            this.columns.forEach((column: TableColumn, columnIndex: number) => {
                newRow.key = rowIndex;
                let columnValue = Report.formatNumber(row[columnIndex], 2);
                if (typeof columnValue === 'string') {
                    newRow[column.key] = this.$t(columnValue);
                } else {
                    newRow[column.key] = columnValue;
                }
            });

            return newRow;
        });

        // Calculate total
        const total: any = {};

        if (this.data.statisticReport.data.fields) {
            for (let i = 0; i < this.data.statisticReport.data.fields.length; i++) {
                total.key = 'total';
                if (i === 0) {
                    total[this.columns[i].key] = this.$t('Total');
                } else {
                    total[this.columns[i].key] = this.addTotal(i);
                }
            }
        }

        data?.push(total);

        return data;
    }

    private onChange(
        _: any,
        __: any,
        sorter: TableSorter,
        dataSource: { currentDataSource: Array<Record<string, any>> }
    ) {
        // Sort data based on data type and keep total row at the bottom
        let data = dataSource?.currentDataSource?.slice();
        if (!data || !sorter?.order) {
            return;
        }

        const sortFunctions: SortFunctions = {
            string: (a: string, b: string) => a.localeCompare(b) as 1 | -1,
            number: (a: number, b: number) =>
                parseFloat(String(a).replace(/, /g, '')) - parseFloat(String(b).replace(/, /g, '')),
            date: (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime(),
        };

        const sortOrder = sorter.order === 'ascend' ? 1 : -1;

        data = data.sort((a: Record<string, any>, b: Record<string, any>) => {
            return sortFunctions[sorter.column.type](a[sorter.columnKey], b[sorter.columnKey]) * sortOrder;
        });

        const total = data.find((row: Record<string, any>) => row.key === 'total');

        if (total) {
            data.splice(data.indexOf(total), 1);
            data.push(total);
        }

        this.sortedTableData = data;
    }

    private addTotal(column: number) {
        if (this.data.statisticReport.data.values) {
            let total = 0;
            for (let i = 0; i < this.data.statisticReport.data.values.length; i++) {
                total += this.data.statisticReport.data.values[i][column];
            }
            if (/[a-zA-Z]/.test(String(total))) {
                return '';
            }

            return Report.formatNumber(total);
        }

        return 0;
    }

    private changeChartSize(size: 'S' | 'M' | 'L') {
        this.data.size = size;
    }
}
