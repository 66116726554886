import i18n from '@/i18n';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import LeadsIcon from '@/components/icons/LeadsIcon.vue';
import ProductCatalogIcon from '@/components/icons/ProductCatalogIcon.vue';
import { generateAndStoreSearchParameters } from '@/helpers/SearchHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import router from '@/router';
import { PredefinedCustomSearchParameters } from '@/interfaces/general/PredefinedCustomSearchParameters';
import Project from '@/models/Project';

export function StaticLinkConfigurationSource() {
    return [
        {
            isExternal: false,
            icon: LeadsIcon,
            label: i18n.t('Upiti'),
            route: RouteNames.home,
            type: ProjectTypes.Lead,
        },
        {
            isExternal: true,
            route: 'https://www.roltek.si/user/?src=doc',
            icon: ProductCatalogIcon,
            label: i18n.t('Katalog'),
        },
    ];
}

export function generateSearchFilterAndNavigateHome(
    predefinedCustomSearchParameters: PredefinedCustomSearchParameters
) {
    if (predefinedCustomSearchParameters.currentUser == null) {
        return;
    }

    generateAndStoreSearchParameters(predefinedCustomSearchParameters);

    // Generate route and open in a new tab
    const routeData = router.resolve({ name: predefinedCustomSearchParameters.route });
    window.open(routeData.href, '_blank', 'noopener');
}

export async function getNumberOfProjectsBasedOnSearchFilters(predefinedCustomSearchParameters: any) {
    const states = [predefinedCustomSearchParameters.type];

    if (predefinedCustomSearchParameters.type === 'offer') {
        states.push('request');
    }

    const statesQuery = states.map((state) => `state:${state}`).join('|');

    const assignedUserId =
        predefinedCustomSearchParameters.currentUser && predefinedCustomSearchParameters.type !== 'request'
            ? `^(assignedUser.id:${predefinedCustomSearchParameters.currentUser.id})`
            : '';

    let query = `&q=temporary:0^(${statesQuery})${assignedUserId}`;


    if (predefinedCustomSearchParameters.label != null) {
        query += `^(projectLabelId:${predefinedCustomSearchParameters.label.id})`;
    }

    if (predefinedCustomSearchParameters.orderStatus != null) {
        query += `^(orderStatus.id:${predefinedCustomSearchParameters.orderStatus.id})`;
    }

    return await Project.fetchForTotalProjectNumber(query);
}
