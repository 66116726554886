
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AppSidebar from '@/components/global/sidebar/AppSidebar.vue';
import Tasks from '@/views/Tasks.vue';
import Comments from '@/views/Comments.vue';
import ReportRepository from '@/repositories/ReportRepository';
import { StatisticsCmsColumns } from '@/interfaces/components/cms/StatisticsCmsColumns';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { ReportTimeFrame } from '@/interfaces/components/Statistics/ReportTimeFrame';

@Component({
    name: 'StatisticsSidebar',
    components: {
        AppSidebar,
        Tasks,
        Comments,
    },
})
export default class StatisticsSidebar extends Vue {
    @Prop({ required: true }) private sidebarData!: any;
    @Prop({ required: true }) private isEdit!: boolean;
    @Prop({ required: true }) private timeFrame!: ReportTimeFrame;
    @Prop({ required: true }) private userId!: string;

    private reportId: string = '';
    private reportSize: string = '';
    private currentlySelectedUser: string = '1';
    private newsSize: string = '';

    private get reportList(): StatisticsCmsColumns[] {
        return ReportRepository.getAll();
    }

    private get allUsers() {
        return CMSUserRepository.getAllExceptArchivedUsers();
    }

    private get years() {
        // return array of years from 2000 to current year
        const years = [];
        for (let i = 2000; i <= new Date().getFullYear(); i++) {
            years.push(i);
        }
        return years.reverse();
    }

    private get weeks() {
        // return array of weeks from 1 to 52 and add current string as first value in array
        const weeks: Array<string | number> = ['Current'];
        for (let i = 1; i <= 52; i++) {
            weeks.push(i);
        }
        return weeks;
    }

    private get months() {
        // return array of months from 1 to 12 and add / string as first value in array
        const months: Array<string | number> = ['/'];
        for (let i = 1; i <= 12; i++) {
            months.push(i);
        }
        return months;
    }

    private addNewReport() {
        if (!this.isReportDataValid()) {
            return;
        }

        const report = this.reportList.find((reportItem) => reportItem.id === this.reportId);

        this.$emit('addNewReport', report, this.reportSize);
    }

    private isReportDataValid() {
        if (!this.reportId) {
            this.$notification.error({
                message: this.$t('No report selected!'),
                description: '',
            });
            return false;
        }

        if (!this.reportSize) {
            this.$notification.error({
                message: this.$t('Please select report size!'),
                description: '',
            });
            return false;
        }

        return true;
    }

    private filterDashboardReportsByDateAndUser() {
        this.$emit('filterDashboardReportsByDateAndUser', this.currentlySelectedUser);
    }

    private filterDashboardReportsByDate() {
        this.$emit('filterDashboardReportsByDate');
    }

    private async mounted() {
        this.currentlySelectedUser = this.userId;
    }

    @Watch('isEdit')
    private onIsEditChange() {
        this.$emit('isEditChange', this.isEdit);
    }
}
