var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"news"},[_c('div',{class:['border-wrapper', { 'edit-border': _vm.isEdit }]},[_c('div',{staticClass:"news-cms-wrapper"},[_c('div',{staticClass:"news__top-bar"},[_c('chart-header',{attrs:{"data":{
                        title: _vm.$t('News'),
                        subtitle: '',
                        id: 'news',
                        name: 'News reports',
                        type: 'news',
                    },"isEdit":_vm.isEdit,"isResizable":false,"shouldHideDownloadPNGOption":true}})],1),_c('div',{staticClass:"news-cms-content"},[_c('div',{staticClass:"news-table-wrapper"},[_c('a-table',{ref:"tableRef",style:({ 'font-size': '0.75rem' }),attrs:{"id":"news-table","columns":_vm.columns,"data-source":_vm.activeNews,"pagination":{ pageSize: 5 },"rowClassName":() => 'news__table-row',"rowKey":(record) => record.key,"customRow":(record) => {
                                return {
                                    on: {
                                        click: () => _vm.openNewsReport(record.key), // click row
                                    },
                                };
                            }}})],1)]),_c('a-modal',{attrs:{"cancelButtonProps":{ style: { display: 'none' } },"ok-text":_vm.$t('Close'),"destroyOnClose":"","wrapClassName":"news-modal","centered":"","width":"80vw"},on:{"ok":_vm.closePopup},model:{value:(_vm.isNewsModalVisible),callback:function ($$v) {_vm.isNewsModalVisible=$$v},expression:"isNewsModalVisible"}},[_c('news-add-new',{attrs:{"isReadOnly":true,"id":_vm.activeNewsId},on:{"closePopup":_vm.closePopup}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }