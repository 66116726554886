var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tasks-wrapper"},[_c('div',{staticClass:"tasks__header"},[_c('span',{staticClass:"tasks__title"},[_vm._v(_vm._s(_vm.$t('Coming up tasks')))])]),_c('div',{staticClass:"tasks__content"},_vm._l((_vm.data),function(field){return _c('div',{staticClass:"tasks__field"},[_c('div',{staticClass:"time-stamp"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(field.time))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(field.date))])]),_c('div',{staticClass:"client"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(field.name))]),_c('div',{staticClass:"status"},[_vm._v(_vm._s(field.status))])]),_c('router-link',{attrs:{"to":{
                    name: _vm.routeNames.project,
                    params: {
                        id: field.projectId,
                    },
                    query: {
                        initialTab: _vm.projectTabValues.Basic,
                    },
                },"target":"_blank"}},[_c('a-icon',{staticClass:"field-icon",attrs:{"type":"eye"}})],1)],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }